@import "sass-lib";

@media (min-width: 0px) and (max-width: 690px) {
	h1{
		&.h1s2{font-size: 40px;}
	}
	h2{font-size: 20px;}
	h3{
		font-size: 20px;
		&.h3s2{font-size: 25px;}
	}
	h5, h6{font-size: 16px;}
	input, textarea, select{font-size: 14px;}

	.wrapper, .wrap-md, .wrap-md2{padding: 0 20px;}
	.headpadd{margin-top: 74px;}
	.wrap-sm{
		&.sidepadd{padding: 0;}
	}
	.box-bysidecontent{
		&.margtop{
			h2{ font-size: 20px; max-height: 88px;}
			.left{ width: 100%; }
			.right{ width: 100%; }
		}
	}

	header{
		.logo{
			img{width: 44px;}
		}
		.nav-lang{display: none; margin-right: 0;}
		.head-right{
			padding-top: 18px;
			> .btn-rounded{@include transition(none);}
		}
		.navigation{
			text-align: center; padding-top: 104px; margin-top: -74px;
			> li{
				> a{font-size: 18px; padding: 15px 20px;}
			}
			.btn-rounded{display: inline-block; margin-top: 30px;}
		}
		.box-sosmed{position: relative; bottom: auto; left: auto; right: 0; margin-top: 40px;}
	}

	.box-slidehome{
		.box-filter{position: relative; bottom: auto; left: auto; right: auto; top: auto;}
	}
	.box-filter{
		padding: 30px 20px;
		.left{
			.lineh{margin-bottom: 5px;}
		}
		.row{width: 100%; margin-right: 0;}
	}
	.slidehome{
		.list{
			figure{height: 218px;}
			.desc{
				left: 20px; right: 20px; top: 50px; bottom: auto; @include transform(translateY(0));
				h1{font-size: 24px; line-height: 30px;}
				h5{font-size: 16px;}
				/*.link{display: none;}*/
				.btn-rounded{height: 30px; line-height: 26px; font-size: 12px; padding-left: 10px; padding-right: 10px; border-width: 2px;}
			}
		}
		.slick-dots{bottom: 15px;}
	}
	.selectcustom{
		.btnselect{font-size: 14px;}
		.dropselect{
			.lsdropselect{font-size: 14px;}
		}
	}
	.sec-service{
		padding-bottom: 50px; background: transparent;
		.top-service{padding-top: 50px; background: transparent;}
		.servicelist{
			padding: 0 20px;
		}
		.in-service{
			h2{font-size: 20px;}
		}
	}
	.servicelist{
		.ls-service{width: 100%; margin-bottom: 20px;}
	}
	.sec-fasility{
		background: #fef4dc; padding-top: 40px; padding-bottom: 60px;
		h2{font-size: 20px;}
	}
	.sidefasility{
		.left, .right{width: 100%;}
		.left{margin-bottom: 70px;}
	}
	.slidefasility{
		.slick-dots{
			right: 0; left: 0; margin: 15px auto 0; text-align: center;
			li{
				float: none; display: inline-block; margin-bottom: 5px; height: auto; margin-right: 20px;
				button{width: 10px; height: 10px;}
			}
		}
	}
	.slidecapsfasility{
		.list{
			h3{font-size: 20px;}
			p{max-height: none;}
		}
	}
	.sec-about{
		padding: 30px 0 40px; min-height: 0;
		> figure{
			position: relative; top: auto; right: auto; z-index: auto; max-width: none; padding: 0 20px; bottom: auto; left: auto;
			img{width: 100%;@include borderRadius(10px);}
		}
		.desc-abouthome{
			width: 100%; margin-bottom: 40px; text-align: center;
			h2{font-size: 20px; line-height: 28px; margin-bottom: 10px;}
			.link{text-align: center;}
		}
	}
	.slidenews{
		.list{
			.desc{font-size: 16px;}
		}
	}
	.contentwp{
		h2{line-height: 1.4;}
		p{font-size: 14px; line-height: 24px; height: auto;}
		ol, ul{
			> li{font-size: 14px;line-height: 24px; padding-left: 25px;}
		}
	}
	.ttlcontent{
		h2, h5{margin-bottom: 10px;}
		br{display: none;}
	}
	.box-acc{
		.ls-acc{
			margin-left: 15px; margin-right: 15px;
			p{line-height: 24px; margin-bottom: 10px;}
			a{font-size: 14px;}
			.btn-acc{
				padding: 20px 40px 20px 20px; font-size: 14px; line-height: 18px;
				.lnacc{
					&:before, &:after{width: 16px; height: 3px; right: 15px;}
				}
			}
			.ctn-acc{padding: 20px;}
		}
	}
	.boxsliderewards{margin-top: 0; padding: 0 20px;}
	.sliderewards{
		margin-bottom: 30px;
		.list{
			margin: 0;
			&.prev1, &.prev2, &.prev3, &.next1, &.next2, &.next3{@include transform(scale(1));}
			&.slick-current{@include transform(scale(1)); margin: 0;}
		}

	    &.minseven, &.minfive, &.minfour, &.minthree, &.minduo{
	        .list{
	            margin: 0;
	            &.slick-current{@include transform(scale(1));z-index: 2;}
	            &.prev1{@include transform(scale(1));z-index: -1;}
	            &.prev2{@include transform(scale(1));z-index: -2;}
	            &.prev3{@include transform(scale(1));z-index: -3;}
	            &.next1{@include transform(scale(1));z-index: -1;}
	            &.next2{@include transform(scale(1));z-index: -2;}
	            &.next3{@include transform(scale(1));z-index: -3;}
	        }
	    }
	}
	.slidecapsrewards{
		margin-bottom: 80px;
		&:before{bottom: -65px;}
		.list{
			h4{font-size: 18px; margin-bottom: 10px;}
			p{font-size: 14px;}
		}
		.slick-prev, .slick-next{bottom: -50px;}
	}
	.content{padding-top: 30px; background: #fef4dc;}
	.boxslidemilestone{padding: 0 40px;}
	.dotsmilestone{
		.slick-prev{
			left: -40px;
			&:hover{left: -40px;}
		}
		.slick-next{
			right: -40px;
			&:hover{right: -40px;}
		}
	}
	.servicerow{
		.ls-servicerow{
			display: flex; flex-direction: column; -webkit-flex-direction: column; -moz-flex-direction: column; position: relative; 
			.ttlservicerow{
				margin-bottom: 10px; padding-bottom: 10px; @include boxSizing(border-box); min-height: 50px; display: table; width: 100%;
				h5{display: table-cell; vertical-align: middle;}
			}
			.desc, .img{width: 100%;}
			.desc{order: 2; padding-top: 0;}
			.img{
				order: 1;
				.btn-rounded{position: absolute; bottom: -55px; right: 0;}
			}
			
			&:last-child{margin-bottom: 60px;}
			&:nth-child(even){
				.ttlservicerow{
					padding-left: 52px; padding-right: 0;
					.icwp{right: auto; left: 0;}
					&.noico{padding-left: 0;}
				}
				.desc{text-align: left;}
			}
		}
	}
	.slidecontent{
		margin-bottom: 60px;
		.slick-dots{bottom: -35px;}
	}
	.box-spesialis{
		figure{margin-bottom: 0;}
		.left, .right{width: 100%;}
		.left{margin-bottom: 20px;}
	}
	.slidecapsspesialis{
		h3{font-size: 20px; margin-bottom: 10px;}
		p{height: auto;}
	}
	.grid-accordion{
		padding: 0; background: transparent;
		.ga-panel{
			img{width: 100%;}
			h4{margin-bottom: 0 !important;}
			p{height: 46px;}
			> a{
				position: absolute; top: 0; left: 0; right: 0; bottom: 0;
				img{max-width: none !important; height: 100% !important; object-fit: cover; object-position: top left;}
			}
		}
		.ga-layer{
			&.ga-padding{padding-left: 15px; padding-right: 15px;}
		}
	}
	.box-toplist{padding-top: 40px;}
	.classlist{
		margin-left: 0; margin-right: 0; padding-bottom: 0;
		.ls-classlist{
			width: 100%; padding-left: 0; padding-right: 0; padding-bottom: 0; margin-bottom: 20px;
			.desc{
				padding-left: 0; padding-right: 0; width: 100%; top: 0; bottom: 0;
				.ttlclass{position: absolute; top: 0; left: 0; right: 0; z-index: 2;}
				.descclass{position: absolute; top: 0; left: 0; right: 0; height: 100%; overflow: auto; padding-top: 60px; @include boxSizing(border-box); z-index: 1;}
			}
			.ttlclass, .descclass{padding-left: 20px; padding-right: 20px;}

			&:nth-child(2n+1){
				clear: both;
				.desc{left: 0%;}
			}
			&:nth-child(2n){
				.desc{left: 0%;}
			}

			&.active{
				figure{
					&:before{opacity: 0; visibility: hidden;}
				}
				&:hover{
					figure{
						&:before{opacity: 0; visibility: hidden;}
					}
				}
			}
		}
	}
	.row_list{
		.col{
			&.col_5{width: 100%;}
		}
	}
	.box-form .row_list, .box-form.row_list{
		.col{margin-bottom: 25px;}
	}
	.tanyadokter{
		padding-bottom: 195px;
		img{width: 100%; height: 160px; object-fit: cover; object-position: bottom;}
		.fbtanyadokter{
			padding-top: 145px;
		}
	}
	.box-lsdokter{
		.lsdokter{
			h3{font-size: 20px;}
			.img, .desc{width: 100%;}
			.img{
				text-align: center;
				figure{
					img{max-height: 320px;}
				}
			}
			.desc{font-size: 14px;}
		}
	}
	.bysidebtn{
		.left, .right{
			.btn-rounded{width: 100%; text-align: center;}
		}
	}
	.detaildokter{
		h3{font-size: 18px; margin: 15px 0 20px;}
		h6{font-size: 14px;}
		> .left, > .right{width: 100%;}
		> .left{
			margin-bottom: 40px;
			.btn-rounded{width: 100%; text-align: center;}
		}
		.bysidebtn{
			margin-bottom: 40px;
			a{font-size: 14px;}
		}
	}
	.box-roundedgrey{
		.ttlroundedgrey{padding: 15px 20px; font-size: 16px; line-height: 22px;}
	}
	.tbdiv{
		.lf{width: 35%;}
	}
	.promolg{
		h3{font-size: 20px; height: auto; margin-bottom: 10px;}
		p{height: auto;}
	}
	.pagination{
		a{
			width: 30px; height: 30px; line-height: 30px; margin: 0 2px;
			&.active{margin: 0 10px;}
			&.prev, &.next{width: 25px; height: 25px; border: none;}
		}
	}
	.mapcontact{
		margin-left: -20px; margin-right: -20px; margin-bottom: -20px;
		.map_contact{height: 280px;}
	}
	.frm-side{
		h2{font-size: 20px; height: auto; margin-bottom: 15px;}
		.left, .right{width: 100%;}
		.right{display: none;}
	}

	table{
		&.table-doctor{
			background: transparent; overflow: auto; @include borderRadius(0);
			thead{display: none;}
			th, td{
				display: block;
				&:before{content: attr(data-title); display: block; margin-bottom: 5px; font-weight: 700; color: $orange;}
			}
			tr{display: block; background: $white; margin-bottom: 15px; @include borderRadius(10px); padding: 10px 0;}
		}
	}


	.box-schedule{
	    .ls-schedule{
	        .lf, .rg{width: 48%;}
	        .rg{text-align: right;}
	    }
	}

	.m-a-left{text-align: left;}

	footer{
		.foot-top{
			&:before{display: none;}
			h6{font-size: 16px;}
			.left, .right{width: 100%; padding: 20px; text-align: center;}
			.left{background: #ceac82;}
			.right{background: #c4975f;}
			.wrap-md{padding: 0;}
		}
		.foot-bottom{
			.logo-foot, .centerfoot, .rightfoot{width: 100%; text-align: center;}
			.box-inquiry{text-align: center;}
			.box-sosmed{text-align: center;}
			.logo-foot{margin-bottom: 10px;}
			.centerfoot{
				ul{
					margin-right: 0; margin-bottom: 15px;
					li{width: auto; display: inline-block; float: none; padding: 0 6px; margin: 0 0 10px;}
				}
			}
		}
	}
}

@media (min-width: 0px) and (max-width: 440px) {
	header{
		.head-right{
			> .btn-rounded{font-size: 13px; padding-left: 10px; padding-right: 10px; height: 35px; line-height: 28px; @include borderRadius(5px);}
		}
		/*.nav-lang{display: block;}*/
		.btn-burgmenu{margin-left: 20px;}
	}

	.slidehome{
		.list{
			.desc{
				h5{margin-bottom: 5px;}
				.lineh{margin-bottom: 10px;}
			}
		}
	}

	.box-filter{
		.right{
			input[type=submit]{width: 100%; text-align: center; background-position: 94% 50%;}
		}
	}
	.btn-tab{
		.lsbtn-tab{padding-left: 10px; padding-right: 10px;}
	}
	.sec-news{
		padding-bottom: 40px;
		.sm-news{
			.ls-news{
				width: 100%; margin-bottom: 30px;
				figure{
					&:before{width: 54px; height: 54px; bottom: -15px}
				}
			}
		}
	}
	.servicerow{
		.ls-servicerow{
			.img{
				.btn-rounded{width: 100%; text-align: center; font-size: 14px; height: 45px; line-height: 39px;}
			}
			.desc{
				p{margin-bottom: 10px;}
			}
		}
	}
	.g-recaptcha{@include transform(scale(0.9)); @include transformOrigin(0 0);}
	.promolist{
		.ls-promolist{
			width: 100%;
			h6{height: auto; font-size: 18px;}
			p{line-height: 24px; height: auto;}
			&:nth-child(2n+1){clear: inherit;}
			&:nth-child(even){
	            /*&:before{top: 0;}*/
	            figure{
	                /*order: 1;*/
	                /*&:before{bottom: -16px; left: 0; right: auto; top: auto;}*/
	                /*img{@include borderRadius(10px 10px 0 0);}*/
	            }
	            /*.desc{order: 2;@include borderRadius(0 0 10px 10px); padding-bottom: 30px; padding-top: 42px;}*/
			}
		}
	}
}